import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

html,
body {
    padding: 0;
    margin: 0;
    background-color:rgba(0,0,0,0.8) ;
    /* font-family: 'Fredoka One', cursive; */
    font-family: 'Fredoka One', cursive !important;
    font-family: 'Kanit', sans-serif!important;
    font-family: 'Poppins', sans-serif;
}

iframe{
position:absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index:20;
}
`;
