import styled from "styled-components";
import React, { useEffect } from "react";
import ReactPlayer from "react-player/lazy";
// import axios from "axios";
import { useNavigate } from "react-router-dom";

var platform = require("platform");

// const ReactPlayer = dynamic(() => import("react-player/lazy"), {
//   loading: () => <p>Loading ...</p>,
//   ssr: false,
// });

const VideoComponent = ({
  videoItem,
  nextVideo,
  serviceName,
  showControls = true,
}) => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const navigation = useNavigate();
  const videoPlaying = async (id) => {
    let url = `/api/increment-video-view?id=${id}`;
    const res = await fetch(url);
    const data = await res.json();
  };
  const isAndroid = platform.name.includes("Chrome");

  const videoUrl = isAndroid
    ? videoItem.vurl
    : videoItem.vurl.replace("mpd", "mp4");

  if (videoItem.vurlaws != "")
    return (
      <VideoComponentStyled>
        <ReactPlayer
          url={videoItem.vurl}
          // url="https://videos.visiontrek.in/uploads/videos/ndoto/video-1651144274901/video-1651144274901.mp4"
          playing={true}
          controls={showControls}
          width="100%"
          height="100%"
          style={{ position: "absolute" }}
          progressInterval={200}
          onStart={() => videoPlaying(videoItem.id)}
          playsinline={true}
          onEnded={() => navigation(`/video/${nextVideo.id}`)}
          light={isAndroid ? false : videoItem.imgurl}
          // onReady={() => sendModal()}
          // config={{
          //   file: {
          //     forceHLS: !isSafari,
          //     forceVideo: true,
          //     forceDASH: true,
          //     hlsOptions: {
          //       autoStartLoad: true,
          //       maxMaxBufferLength: 600,
          //       backBufferLength: Infinity,
          //       maxBufferSize: 60 * 1000 * 1000,
          //       maxBufferHole: 0.5,
          //       attributes: { preload: "auto" },
          //       forceAudio: true,
          //     },
          //   },
          // }}
        />
      </VideoComponentStyled>
    );
};

export default VideoComponent;

const VideoComponentStyled = styled.div`
  min-height: 35vh;
  overflow: hidden;
  background-color: black;
  position: relative;
`;
