import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import userContext from "../../components/context/UserContext";
import styled from "styled-components";
import { SuspenseImg } from "../../SuspenseImage/SuspenseImage";
import Spinner from "../../components/Spinner/Spinner";
import SimilarItems from "../../components/SimilarItems/SimilarItems";
import VideoComponent from "../../components/VideoComponent";

const VideoDescription = () => {
  const navigation = useNavigate();
  const [loader, setLoader] = useState(true);
  const [otherItems, setOtherItems] = useState([true]);
  const [selectedItem, setSelectedItem] = useState([true]);
  const [nextVideo, setNextVideo] = useState("");
  const params = useParams();
  const { state } = useContext(userContext);

  const { videos } = state;

  useEffect(() => {
    if (videos.length > 0) {

      const selectedItem = videos.find((item) => item.id == params.id);
      setSelectedItem(selectedItem);
      const catId = videos.find((item) => item.id == params.id).category_id;

      //   console.log(catId);

      const otherItems = videos.filter(
        (item) => item.category_id === catId && item.id !== params.id
      );
      // console.log(otherItems);
      setOtherItems(otherItems);
      setLoader(false);
    }
  }, [videos, navigation]);

  if (loader)
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <GameDescriptionStyled>
      <div className="image-section">
        <VideoComponent videoItem={selectedItem} />
      </div>

      <div className="inner-elements"></div>
      <h3>Similar Videos</h3>
      <div className="game-collection ">
        <SimilarItems otherItems={otherItems} />
      </div>
    </GameDescriptionStyled>
  );
};

export default VideoDescription;

const GameDescriptionStyled = styled.div`
  min-height: 100vh;

  .image-section {
    height: 30vh;

    img {
      height: 100%;
      width: 100%;
    }
  }
  background-image: linear-gradient(to right, #141e30, #243b55);
  .inner-elements {
    padding: 40px;
  }

  h3 {
    width: 90%;
    color: black;
    font-weight: 400;
    background-image: linear-gradient(to right, #0099f7, #f11712);
    padding: 5px;
    border-radius: 5px;
    margin-left: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
`;
