import React from "react";
import logo from "../../assets/images/toonflix.png";

const Tnc = () => {
  return (
    <>
   
    <div style={{
      minHeight: "100vh",
      backgroundColor: "white",
      position: "absolute",
      top: "0",
      left: "0",
      padding: "40px"
    }}>
      <div style={{
       display: "flex",
       justifyContent: "center",
       marginBottom: "10px"
      }}>
      <img src={logo} alt="logo" width={"250px"} height={"60px"} />
      </div>
       
      {/* Hello world */}
      <h2>Conditions générales de service</h2>
      Dans le cas où l'utilisateur n'est pas d'accord avec l'une de ces
      conditions, il ne doit pas utiliser les services d'abonnement proposés par
      Panz. Panz a le droit de suspendre ou de résilier les services de tout
      client qui ne respecte pas les présentes conditions générales ou toute
      autre obligation contractuelle connexe. Panz propose des services et des
      téléchargements de contenu de divertissement via ses services WAP pour les
      téléphones mobiles compatibles. Une fois la commande et le paiement
      effectués, l'utilisateur peut recevoir ou télécharger des produits et
      services sur des téléphones mobiles et autres appareils compatibles.
      <h3>Résiliation / Désinscription</h3>
      Vous pouvez vous désinscrire de votre abonnement à tout moment en vous
      connectant au portail ou par e-mail : panz.support1@panzcon.com pour
      obtenir de l'aide.
      <h3>Responsabilité de l'utilisateur</h3>
      Panz ne seront pas tenus responsables des pertes ou dommages subis par
      l'Utilisateur résultant de l'inexécution de ces devoirs et obligations. De
      même, l'Utilisateur s'engage à ne pas utiliser le système et le Service
      des consultants Panz de manière inappropriée, à respecter les lois et à
      respecter les droits des tiers, y compris les droits de marques, brevets,
      droits d'auteur et tout autre droit intellectuel ou industriel. droits de
      propriété.
      <h3>Responsabilité de Panz</h3>
      Panz ne garantit pas la qualité, l'exactitude, la fiabilité, la correction
      ou la moralité des données, programmes, informations ou opinions quelle
      qu'en soit l'origine qu'elles proviennent de son réseau ou des réseaux
      auxquels l'Utilisateur peut accéder. En s'inscrivant, l'Utilisateur
      accepte que Panz soit exonéré de toute responsabilité liée à ses pages
      WAP. L'Utilisateur assume également l'entière responsabilité des
      conséquences, dommages ou actions pouvant découler de l'accès à ces
      contenus, ainsi que de leur reproduction ou diffusion.
      <br />
      <h3>Droits de propriété intellectuelle</h3>
      Sur le WAP et les sites Web de Panz, le contenu et les droits de propriété
      intellectuelle, la programmation et les conceptions Web sont entièrement
      protégés par les droits d'auteur, par conséquent, pour leur reproduction,
      communication, divulgation et distribution, une autorisation doit être
      demandée. De même, tous les logos qui apparaissent sur le WAP et les sites
      Web appartiennent à Panz, par conséquent, leur reproduction ou
      distribution est interdite par quelque moyen que ce soit sans obligation,
      avertissement ou autorisation du propriétaire. ces termes et conditions
      sont régis par Algerian law. Aucune des parties ne peut engager de
      procédure judiciaire en relation avec tout litige découlant du présent
      accord tant qu'elle n'a pas tenté de régler le litige.
    </div>
    </>
  );
};

export default Tnc;
