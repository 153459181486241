import React, { useState } from "react";

import { Button, Input, Form, Select, Checkbox, Switch, Space } from "antd";
import { ShowOTP } from "../ShowOTP/ShowOTP";
import ShowPack from "../ShowPack.js/ShowPack";
import { Link } from "react-router-dom";
import LanguageSwitch from "../../LanguageSwitch";
// import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const NonHeaderFlow = ({
  formSubmit,
  showPack,
  showOTP,
  OTPSubmit,
  packSubmit,
  isFrench,
  setFrench,
}) => {
  const [number, setNumber] = useState("");

  const [isBoxChecked, setiBoxChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [form] = Form.useForm();
  const { t } = useTranslation()
  //   const onGenderChange = (value) => {
  //     switch (value) {
  //       case "daily":
  //         form.setFieldsValue({
  //           pack: "Daily",
  //         });
  //         return;

  //       case "weekly":
  //         form.setFieldsValue({
  //           pack: "Weekly",
  //         });
  //         return;
  //     }
  //   };

  return (
    <>
      <LanguageSwitch />
      {!showPack && !showOTP ? (
        <Form
          form={form}
          name="form-field"
          initialValues={{
            remember: true,
          }}
          // onFinish={onFinishOTP}
          onFinish={isBoxChecked && formSubmit}
          autoComplete="off"
        >
          <Form.Item
            name="msisdn"
            // label="Input Number"
            // label=" Numero d'identification"
            label={t("Numero d'identification")}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              // style={{ width: "70%" }}
              value={number}
              // placeholder="Entrez un numéro à 12 chiffres 213xxxxxxxxx"
              placeholder={t("Entrez un numéro à 12 chiffres 213xxxxxxxxx")}
              maxLength="12"
              size="large"
            // onChange={(e) => setNumber(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Checkbox
              onChange={(e) => {
                setiBoxChecked(e.target.checked);
                // console.log(e.target.checked);
              }}
            >
              {" "}
              <Link to="/landing/toonflix/tnc">
                {/* Accepter les termes et conditions */}
                <h5>
                  {t("Accepter les termes et conditions")}
                  {/* {`${
                    isFrench ? (
                      <h5>Accepter les termes et conditions</h5>
                    ) : (
                      "قبول الشروط والأحكام"
                    )
                  }`} */}
                </h5>
                {/* {`${isFrench ?  <h5>Accepter les termes et conditions</h5> : "قبول الشروط والأحكام"}`} */}
              </Link>
            </Checkbox>
          </Form.Item>

          {!isBoxChecked && (
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <h5 style={{ color: "red" }}>
                Please accept terms and conditions
              </h5>
            </Form.Item>
          )}

          <Button
            className="custom-button"
            size="large"
            type="primary"
            htmlType="submit"
          // onClick={formSubmit}
          // style={{ textAlign: "center" }}
          >
            {/* Subscribe */}

            {t("s'abonner")}
          </Button>
        </Form>
      ) : null}

      {showOTP ? (
        <ShowOTP showOTP={showOTP} OTPSubmit={OTPSubmit} isFrench={isFrench} />
      ) : null}
      {showPack ? (
        <ShowPack
          packSubmit={packSubmit}
          isFrench={isFrench}
          isBoxChecked={isBoxChecked}
        />
      ) : null}
    </>
  );
};

export default NonHeaderFlow;
