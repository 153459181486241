import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SuspenseImg } from "../../SuspenseImage/SuspenseImage";

const SimilarItems = ({ otherItems }) => {
  return (
    <SimilarItemsStyled>
      {otherItems.map((item) => (
        <div className="card-item" key={item.id}>
          <Link to={`/landing/toonflix/video/${item.id}`}>
            <div className="overlay-buttons">
              <div className="box">Play Now</div>
            </div>
            <SuspenseImg
              // style={contentStyle}
              src={item.imgurl}
              alt={item.gamename}
            />
          </Link>
        </div>
      ))}
    </SimilarItemsStyled>
  );
};

export default SimilarItems;

const SimilarItemsStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  .card-item {
    cursor: pointer;
    position: relative;
    /* min-height: 16vh; */
    flex: 0 0 44%;
    max-width: 100%;
    /* padding: 0 10px; */
    margin: 10px 0px;
    margin: 10px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    transition: 1s ease;

    :hover {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
      transition: 1s ease;
    }

    .overlay-buttons {
      position: absolute;
      background-color: rgba(1, 1, 1, 0.2);
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .box {
        background: linear-gradient(to right, gold, darkorange);
        color: white;
        --width: 150px;
        --height: calc(var(--width) / 3);
        width: var(--width);
        height: var(--height);
        text-align: center;
        line-height: var(--height);
        font-size: calc(var(--height) / 2.5);
        font-family: sans-serif;
        letter-spacing: 0.2em;
        border: 1px solid darkgoldenrod;
        border-radius: 2em;
        transform: perspective(500px) rotateY(-15deg);
        text-shadow: 6px 3px 2px rgba(0, 0, 0, 0.2);
        box-shadow: 2px 0 0 5px rgba(0, 0, 0, 0.2);
        transition: 0.5s;
        position: relative;
        overflow: hidden;
      }

      .box:hover {
        transform: perspective(500px) rotateY(15deg);
        text-shadow: -6px 3px 2px rgba(0, 0, 0, 0.2);
        box-shadow: -2px 0 0 5px rgba(0, 0, 0, 0.2);
      }

      .box::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, transparent, white, transparent);
        left: -100%;
        transition: 0.5s;
      }

      .box:hover::before {
        left: 100%;
      }
      /* display: none; */

      :hover {
        opacity: 1;
      }
    }
    img {
      z-index: 2;
      /* border-radius: 10px !important; */
      mix-blend-mode: unset !important;
      height: 137px !important;
      width: 100%;
      max-height: 100%;
      /* object-fit: cover; */
      display: inline-block;
      vertical-align: middle;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      -webkit-filter: brightness(90%);
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -o-transition: all 1s ease;
      -ms-transition: all 1s ease;
      transition: all 1s ease;

      /* object-position: center; */
    }
    @media (min-width: 768px) {
      flex: 0 0 22%;
      img {
        height: 200px !important ;
      }
    }
  }
`;
