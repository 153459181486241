import React, { useState } from "react";
import { Input, Form, Button } from "antd";
import { useTranslation } from "react-i18next";

export const ShowOTP = ({ showOTP, OTPSubmit, isFrench }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation()
  // const [otp, setOTP] = useState("");
  const [number, setNumber] = useState("");
  // console.log("showOTP ", showOTP);
  return (
    <>
      <Form
        form={form}
        name="form-field"
        initialValues={{
          remember: true,
        }}
        // onFinish={onFinishOTP}
        onFinish={OTPSubmit}
        autoComplete="off"
      >
        <Form.Item
          name="otp"
          // label="Input otp"
          label={t("entrée otp")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            // style={{ width: "70%" }}
            value={number}
            placeholder={t("entrée otp")}
            maxLength="12"
            size="large"
            // onChange={(e) => setNumber(e.target.value)}
          />
        </Form.Item>
        <Button
          className="custom-button"
          size="large"
          type="primary"
          htmlType="submit"
          // onClick={formSubmit}
          // style={{ textAlign: "center" }}
        >
          {t("soumettre otp")}
        </Button>
      </Form>
    </>
  );
};
