import React, { useState } from "react";
import { Form, Button, Select } from "antd";
import { useTranslation } from "react-i18next";

const ShowPack = ({ packSubmit, isFrench, isBoxChecked }) => {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  
  const onGenderChange = (value) => {
    switch (value) {
      case "daily":
        form.setFieldsValue({
          pack: t("Toonflix quotidien"),
        });
        return;

      case "weekly":
        form.setFieldsValue({
          pack: t("Toonflix hebdomadaire"),
        });
        return;
      default:
        return false;
    }
  };
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      name="form-field"
      initialValues={{
        remember: true,
      }}
      // onFinish={onFinishOTP}
      onFinish={packSubmit}
      autoComplete="off"
    >
      <Form.Item
        name="pack"
        label={t("Selectionner le pack")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder={t("Selectionner le pack")}
          onChange={onGenderChange}
          allowClear
          disabled={loading ? true : false}
        >
          <Option value="daily">{
            t("25 dinars Algerien par jour")
          }</Option>

          <Option value="weekly">{`${t("100 dinars Algerien par semaine")
            }`}</Option>
        </Select>
      </Form.Item>
      <Button
        className="custom-button"
        size="large"
        type="primary"
        htmlType="submit"
      // onClick={formSubmit}
      // style={{ textAlign: "center" }}
      >
        {t("s'abonner")}
      </Button>
    </Form>
  );
};

export default ShowPack;
