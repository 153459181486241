import React, { useState, useEffect } from "react";
import { Button } from "antd";
// import axios from "axios";

import styled from "styled-components";

import { SuspenseImg } from "../../SuspenseImage/SuspenseImage";
import axios from "axios";
import ErrorModal from "../ErrorModal/ErrorModal";
import { useNavigate } from "react-router-dom";
// import bgImage from "../../assets/images/bgimage.jpg";

const Main = ({
  logo,
  serviceType,
  price,
  ani,
  service_id,
  product_id,
  clickid,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState("");
  const [ShowTimer, setShowTimer] = useState(false);
  

  // useEffect(() => {
  //   const fetchToken = async () => {
  //     const response = await axios.get("http://localhost:5980/redirect");
      // console.log("respone ", response.data.accessToken);
  //   };
  //   fetchToken();
  // }, []);

  // console.log("serviceType ", serviceType);

  const formSubmit = async () => {
    try {
      // console.log("ANI ", ani);

      const chargingResponse = await axios.post(
        "https://kunim.mtn.gamewins.co.za/mtnghana/chargeuser/deduct-balance",
        {
          ani: ani,
          service_type: serviceType,
          product_id: product_id,
          service_id: service_id,
          clickId: clickid,
        }
      );
      // console.log(chargingResponse.data);

      setIsError("Dear User, Please confirm your subsription via USSD");
      setShowTimer(true);

      const checkCallbackResponse = await axios.get(
        `https://kunim.mtn.gamewins.co.za/register/checkUssdResponse?ani=${ani}&serviceName=${serviceType}`
      );
      // console.log(checkCallbackResponse.data);

      navigate(`/kidszone/redirect/?ani=${ani}&servicename=${serviceType}`);
    } catch (err) {
      // console.log(err.response);
      // console.log(err.response.data);
      setShowTimer(false);
      if (err.response.data.message == "USSD request not confirmed") {
        setIsError(err.response.message);
        // navigate(
        //   `/gameomania/redirect/?ani=${number}&servicename=${serviceType}`
        // );
      } else {
        // console.log(err.response.data.message.error);
        setIsError(err.response.data.message.error);
      }
    }
  };

  return (
    <MainStyled>
      <div className="inner-items">
        <SuspenseImg src={logo} alt="logo" />

        <h2>Subscription Ghc {price} Per / Day</h2>

        {/* <Tooltip placement="topLeft" title="Subscribe" arrowPointAtCenter> */}
        <Button
          type="primary"
          className="custom-button"
          size="large"
          onClick={formSubmit}
        >
          Subscribe
        </Button>
        {/* </Tooltip> */}

        {isError ? (
          <ErrorModal
            message={isError}
            OnCancel={() => setIsError("")}
            visible={isError ? true : false}
            ShowTimer={ShowTimer}
          />
        ) : null}

        {/* <div className="footer-info">
          {" "}
          <h3>Terms & Conditions</h3> <h3>for help,call us 135</h3>
        </div> */}
      </div>
    </MainStyled>
  );
};

export default React.memo(Main);

const MainStyled = styled.div`
  position: absolute;

  height: 100%;
  width: 100%;
  background-image: linear-gradient(135deg, #52e5e7 10%, #130cb7 100%);
  .custom-button {
    border-radius: 8px;
    background-color: #799f0c;
    font-weight: 800;
  }
  .inner-items {
    height: 80%;
    width: 100%;
    padding: 20px;
    display: flex;

    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      width: 50%;
    }
  }

  h2 {
    color: white;
    padding: 10px;
    font-weight: 800;
  }
  .footer-info {
    margin-top: 100px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    h3 {
      color: white;
    }
  }
`;
