import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import userContext from "../context/UserContext";
import ErrorModal from "../ErrorModal/ErrorModal";
import Header from "../Header/Header";

// import UserState from "../context/UserState";

const Layout = (props) => {
  let location = useLocation();
  let navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["toonflix"]);
  const { state } = React.useContext(userContext);
  const queryParams = new URLSearchParams(window.location.search);

  let ani = queryParams.get("ani");
  let userStatus = queryParams.get("status");
  let lang = queryParams.get("lang");

  const [q] = useSearchParams();
  console.log("ext_ref",q.get("id"));
  if(q.get("id") !== "null") {
    console.log("Setting")
    localStorage.setItem("toonflix_ext_ref", q.get("id"));
  }
  useEffect(() => {
    if (
      (ani !== "null" || userStatus !== "null") &&
      (ani !== null || userStatus !== null)
    )
      setCookie(
        "toonflix",
        {
          ani: queryParams.get("ani"),
          status: queryParams.get("status"),
          lang: queryParams.get("lang"),
        },
        { path: "/" }
      );
  }, []);

  useEffect(() => {
    if (userStatus === null || userStatus === 0) {
      // hea.der less user or non subs
      // console.log("INSIDE HOMMMMM", cookies.toonflix.status);
      let language = localStorage.getItem("toonflix-lang");

      if (Object.keys(cookies).length > 0) {
        // if cookis exist
        if (cookies.toonflix.status === "0") {
          return navigate(`/landing/toonflix/home?lang=${language}&id=${q.get("id")}`);
        } else {
          return navigate("/landing/toonflix/redirect");
        }
      } else navigate(`/landing/toonflix/home?lang=${language}&id=${q.get("id")}`); 
      // if no cookie send to home
    }
    if (userStatus === "1") {
      return navigate("/landing/toonflix/redirect");
    } else navigate(`/landing/toonflix/home?lang=${localStorage.getItem("toonflix-lang")
      }&id=${q.get("id")}`);
  }, []);

  return (
    <div>
      {!location.pathname.includes("home") ? <Header /> : null}
      {/* {cookies.toonflix === "null" && location.pathname !== "/toonflix/home" ? (
        <ErrorModal message="Invalid User" />
      ) : null} */}
      {Object.keys(cookies).length > 0 && (
        <div className="content">{props.children}</div>
      )}
    </div>
  );
};

export default Layout;
