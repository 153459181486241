import { Space, Switch } from 'antd';
import React, { useState, useEffect } from 'react';
import i18n from 'i18next';

const LanguageSwitch = () => {
  const storedLang = localStorage.getItem('toonflix-lang');
  const [lang, setLang] = useState(storedLang || 'fr');

  useEffect(() => {
    if (lang !== storedLang) {
      i18n.changeLanguage(lang);
      localStorage.setItem('toonflix-lang', lang);
    }
  }, [lang, storedLang]);

  return (
    <Space direction="vertical" style={{ padding: '20px' }}>
      <Switch
        checkedChildren="عربى"
        unCheckedChildren="française"
        defaultChecked={lang === 'ar'}
        onChange={(value) => {
          console.log(value);
          setLang(value ? 'ar' : 'fr');
        }}
      />
    </Space>
  );
};

export default LanguageSwitch;
