import React from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

// import DOIRedirect from "../../containers/DOIRedirect/DOIRedirect";
import Layout from "../Layout/Layout";
import Tnc from "../../containers/Tnc/Tnc";

import HomePage from "../../containers/HomePage/HomePage";
import styled from "styled-components";
import Spinner from "../Spinner/Spinner";

import PlayGamesIFrame from "../../containers/PlayGamesIFrame/PlayGamesIFrame";
import VideoDescription from "../../containers/VideoDescription/VideoDescription";
import VideoTest from "../../containers/VideoTest/VideoTest";
const DOIRedirect = React.lazy(() =>
  import("../../containers/DOIRedirect/DOIRedirect")
); // Lazy-loaded

// import DOIRedirect from "../../containers/DOIRedirect/DOIRedirect";

const Navigations = () => {
  
  // console.log(" INSIDE NAVIGATION");
  return (
    // <React.Suspense
    //   fallback={
    //     // <h1>Hwllo</h1>
    //     <SuspenseStyled>
    //       <Spinner />
    //     </SuspenseStyled>
    //   }
    // >
    <CookiesProvider>
      <Layout>
        <Routes>
          <Route
            path="/landing/toonflix/home"
            element={
              <React.Suspense fallback="loadinnnnnnn">
                <HomePage />
              </React.Suspense>
            }
          />
          <Route
            path="/landing/toonflix/tnc"
            element={
              <React.Suspense fallback="loadinnnnnnn">
                <Tnc />
              </React.Suspense>
            }
          />
          <Route
            path="/landing/toonflix/video/:id"
            element={<VideoDescription />}
          />
          <Route
            path="/landing/toonflix/redirect"
            element={
              <React.Suspense fallback="loadinnnnnnn">
                <DOIRedirect />
              </React.Suspense>
            }
          />
          <Route path="/playgames/:slug" element={<PlayGamesIFrame />} />
          <Route path="/videoTest" element={<VideoTest />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Layout>
    </CookiesProvider>
    //
    // </React.Suspense>
  );
};

export default Navigations;

const SuspenseStyled = styled.div`
  background-image: linear-gradient(to right, #141e30, #243b55);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;
