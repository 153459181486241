import React, { useEffect } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

// import "./App.css";

import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

function App() {
  const navigate = useNavigate();
  const [q] = useSearchParams();
  const ext_ref = q.get("id");
  if(ext_ref !== "null" && ext_ref) {
    localStorage.setItem("demo_ref", ext_ref);
  }
  useEffect(() => {
    if (window.location.pathname === "/") navigate("/landing/toonflix/home");
  }, []);

  return <div></div>;
}

export default App;
