import React from "react";
import ReactDOM from "react-dom/client";
// import "antd/dist/antd.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";

import Navigations from "./components/Navigations/Navigation";
import { GlobalStyles } from "./components/GlobalStyled/GlobalStyled";
import UserState from "./components/context/UserState";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./translation.json";
// console.log()
i18n.use(initReactI18next).init({
  lng: localStorage.getItem("toonflix-lang"),
  fallbackLng: "fr",
  resources: {
    fr: {
      translation: translations["fr"],
    },
    ar: {
      translation: translations["ar"],
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Router basename="/">
    <UserState>
      <GlobalStyles />
      <Navigations />
    </UserState>
    <App />
  </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
