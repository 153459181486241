import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { Cookies, useCookies } from "react-cookie";
import { SuspenseImg } from "../../SuspenseImage/SuspenseImage";
import ErrorModal from "../ErrorModal/ErrorModal";
import NonHeaderFlow from "../NonHeaderFlow/NonHeaderFlow";
import HeaderFlow from "../HeaderFlow/HeaderFlow";
const cookiesNew = new Cookies();

let errorObj = {
  "Verify attempt": {
    "fr": "Tentative de vérification échouée. Le code saisi par le client ne correspond pas à celui généré et envoyé par OTP.",
    "ar": "فشلت محاولة التحقق. الرمز الذي أدخله العميل لا يتطابق مع الرمز المولد والمرسل عبر OTP."
  },
  "Not found.": {
    "fr": "Non trouvé. Identifiant de transaction invalide ou OTP déjà expiré.",
    "ar": "غير موجود. معرف المعاملة غير صالح أو انتهت صلاحية رمز التحقق."
  }
}


const ShowLoginInput = ({
  logo,
  serviceType,
  price,
  ani,
  service_id,
  product_id,
  clickid,
}) => {
  const [cookies, setCookie] = useCookies(["toonflix"]);
  const [isFrench, setFrench] = useState(true);
  const navigate = useNavigate();
  const [isError, setIsError] = useState("");
  const [ShowTimer, setShowTimer] = useState(false);

  const [loading, setLoading] = useState(false);
  // either to show pack or not
  const [showPack, setShowPack] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [msisdn, setMSISDN] = useState("");
  const [trxId, setTRXID] = useState("");
  const ext_ref = localStorage.getItem("toonflix_ext_ref");
  console.log("this is ext_ref =>", ext_ref)
  // console.log("SHOW INPUT LOGIN ", isFrench);
  const language = localStorage.getItem("toonflix-lang")
  const formSubmit = async (values) => {
    // console.log("values ani is ", values.ani);
    if (values.msisdn === "12345678")
      return navigate("/landing/toonflix/redirect");

    // console.log("values ", values);
    try {
      setMSISDN(values.msisdn);

      const response = await axios.post(
        "http://api.dzi.toon-flix.com/sendsms",
        {
          ani: values.msisdn,
          type: "otp",
          serviceType: "TOONFLIX",
        }
      );
      setLoading(false);
      // console.log("response ", response);
      setShowPack(false);
      setShowOTP(true);
      setTRXID(response.data.txCode);

      // const response = await axios.post(
      //   // `http://localhost:6247/user/subscribe`,
      //   `/user/subscribe`,
      //   {
      //     ani: values.msisdn,
      //     isSubscribe: true,
      //     pack_type: values.pack,
      //     service_name: "TOONFLIX",
      //   }
      // );
      console.log("RESPONSE  ", response.data);
      // if (response.data.message.statusMessage === "Subscription in progress") {
      //   setCookie("toonflix", values.msisdn, { path: "/" });
      //   navigate("/toonflix/redirect");
      // }
    } catch (err) {
      // console.log(err.response);
    }
  };
  const OTPSubmit = async (values) => {
    // console.log("values of otp ", values);
    // console.log("values of Msidn ", msisdn);
    // console.log("values of trx ", trxId);

    try {
      const response = await axios.post(
        "http://api.dzi.toon-flix.com/verifyOTP/verify",
        {
          ani: msisdn,
          inputOTP: values.otp,
          trxId: trxId,
          serviceType: "TOONFLIX",
        }
      );
      // console.log("RESPONSE OTP ", response.data);

      const responseUser = await axios.get(
        `http://api.dzi.toon-flix.com/user/register?ani=${msisdn}&isSubscribe=false&pack_type=TOONFLIX&service_type=TOONFLIX`
      );
      // console.log("user status ", responseUser.data);
      if (responseUser.data.result === 0) {
        //check after otp if user is sub or not
        setShowPack(true);
        setShowOTP(false);
      } else {
        // console.log("else flow in invalid otp");
        // setCookie(
        //   "toonflix",
        //   {
        //     ani: msisdn === "" ? cookies.toonflix.ani : msisdn,
        //     status: 1,
        //     lang: `${isFrench ? "FR" : "AR"}`,
        //   },
        //   { path: "/" }
        // );
        // navigate(
        //   `/landing/toonflix/redirect/?ani=${
        //     msisdn === "" ? cookies.toonflix.ani : msisdn
        //   }&status=1&lang=${isFrench ? "FR" : "AR"}`
        // );
        // setCookie("toonflix", msisdn, { path: "/" });
        setCookie(
          "toonflix",
          { ani: msisdn, status: 1, lang: responseUser.data.data[0].lang },
          { path: "/" }
        );
        navigate(
          `/landing/toonflix/redirect/?ani=${msisdn}&status=1&lang=${responseUser.data.data[0].lang.toLowerCase()}`
        );
      }
      // setShowPack(true);
      // setShowOTP(false);
    } catch (err) {
      // console.log("err.response ", err.response.data);
      if(err?.response?.data.code === 8001022) {
        // invalid otp
        const ex = err?.response?.data?.message.split(" ").slice(0, 2).join(" ");
        setIsError(errorObj[ex][language]);
        return false;
      }
      //uncomment below for valid error handle
      // setIsError(err.response.data.message);
      // setShowOTP(false);

      // dummy test begin for valid otp

      //hit to check user status
      const response = await axios.get(
        `http://api.dzi.toon-flix.com/user/register?ani=${msisdn}&isSubscribe=false&pack_type=TOONFLIX&service_type=TOONFLIX&ext_ref=${ext_ref}`
      );

      // console.log("user status ", response.data);
      cookiesNew.remove("toonflix", { path: "/" });
      setCookie("toonflix", msisdn, { path: "/" });
      // navigate("/landing/toonflix/redirect");
      // console.log("check otp ->",response)
      if (response.data.result === 0) {
        //check after otp if user is sub or not
        setShowPack(true);
        setShowOTP(false);
      } else {
        // setCookie("toonflix", msisdn, { path: "/" });
        setCookie("toonflix", { ani: msisdn, status: 1 }, { path: "/" });
        navigate(`/landing/toonflix/redirect/?ani=${msisdn}&status=1`);
      }
    }
  };

  const packSubmit = async (values) => {
    // console.log(values);
    let pack =
      values.pack === "Toonflix quotidien" || values.pack === "تونفليكس ديلي"
        ? "Toonflix Daily"
        : "Toonflix Weekly";

    // console.log("pack ", pack);

    // console.log("msisdn ", msisdn === "" ? cookies.toonflix.ani : msisdn);
    try {
      const response = await axios.get(
        `http://api.dzi.toon-flix.com/user/register?ani=${msisdn === "" ? cookies.toonflix.ani : msisdn
        }&isSubscribe=true&pack_type=${pack}&service_type=TOONFLIX&lang=${language.toUpperCase()
        }&mode=web&ext_ref=${ext_ref}`
      );
      // console.log("respone data ", response.data);
      // setCookie("toonflix", msisdn === "" ? cookies.toonflix.ani : msisdn, {
      //   path: "/",
      // });
      // setCookie({ ani: cookies.toonflix.ani, status: 1 }, { path: "/" });
      setCookie(
        "toonflix",
        {
          ani: msisdn === "" ? cookies.toonflix.ani : msisdn,
          status: 1,
          lang: language.toUpperCase(),
        },
        { path: "/" }
      );
      navigate(
        `/landing/toonflix/redirect/?ani=${msisdn === "" ? cookies.toonflix.ani : msisdn
        }&status=1&lang=${language.toUpperCase()}`
      );
    } catch (err) {
      // console.log(err);
    }
  };
  return (
    <ShowLoginInputStyled>
      <div className="inner-items">
        <SuspenseImg src={logo} alt="logo" />
        {/* <h2>Subscription Ghc {price} Per / Day</h2> */}
        <h2></h2>
        {/* if there is no number */}
        {cookies.toonflix.ani === "null" ? (
          <NonHeaderFlow
            formSubmit={formSubmit}
            showPack={showPack}
            OTPSubmit={OTPSubmit}
            showOTP={showOTP}
            packSubmit={packSubmit}
            isFrench={isFrench}
            setFrench={setFrench}
          />
        ) : (
          <HeaderFlow
            formSubmit={formSubmit}
            showPack={true}
            OTPSubmit={OTPSubmit}
            showOTP={false}
            packSubmit={packSubmit}
            ani={cookies.toonflix.ani}
            isFrench={isFrench}
            setFrench={setFrench}
          />
        )}

        {isError ? (
          <ErrorModal
            message={isError}
            OnCancel={() => setIsError("")}
            visible={isError ? true : false}
            ShowTimer={ShowTimer}
          />
        ) : null}
      </div>
    </ShowLoginInputStyled>
  );
};

export default ShowLoginInput;

const ShowLoginInputStyled = styled.div`
  position: absolute;

  height: 100%;
  width: 100%;
  background-image: linear-gradient(135deg, #52e5e7 10%, #130cb7 100%);
  .custom-button {
    border-radius: 8px;
    background-color: #799f0c;
    font-weight: 800;
    margin-top: 20px;
    text-align: center;
    display: block;
    margin: 0 auto;
  }
  .inner-items {
    height: 80%;
    width: 100%;
    padding: 20px;
    display: flex;

    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      width: 50%;
    }
  }

  h2 {
    color: white;
    padding: 10px;
    font-weight: 800;
  }
  .footer-info {
    margin-top: 100px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    h3 {
      color: white;
    }
  }
`;
